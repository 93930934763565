:root {
  --swiper-theme-color: midnightblue;
  --swiper-navigation-size: 16px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
  color: midnightblue;
}

.swiper-button-prev,
.swiper-button-next {
  font-weight: bold;
}
